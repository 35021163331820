import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import { useUserAccount } from '~/hooks/useUserAccount';

export const PAGE_DEFAULT_COLOR = '#300884';

type PageProps = { }

export const Page = ( { children }: PropsWithChildren<PageProps> ) => {
  const userAccount = useUserAccount();

  return (
    <div>
      <div className={ 'relative flex items-center justify-center pointer-events-none' }>
        <div className={ 'w-full h-[240px] bg-center lg:w-[832px] lg:h-[636px] absolute top-[-55px] select-none bg-[url("https://cdn.rewarble.com/ui/spotlight.png")] bg-no-repeat bg-contain animate-spotlight-pulse z-10' } />
        <div className={ 'bg-[var(--page-color)] rounded-[100%] w-full lg:w-[931px] h-[282px] absolute -top-[200px] animate-spotlight-color transition-color z-0' } />
        <div className={ 'bg-[#D9D9D9] bg-opacity-[3%] absolute inset-x-0 h-72 top-24 blur-[88px] select-none' } />
      </div>
      <div className={ classNames(
        userAccount ? 'pt-[120px] lg:pt-[250px]' : 'pt-[120px] lg:pt-[164px]' 
      ) }>
        { children }
      </div>
    </div>
  )
}
